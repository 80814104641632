import { useEffect, useMemo, useState } from 'react';
import { Button, ButtonGroup, Card, Classes, Elevation, H5, Icon, Intent, Popover, Tooltip } from "@blueprintjs/core";
import BarChart from '../components/BarChart';
import { useOne } from '../services/one';
import { Link, useParams } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';

function Location() {
  const { locationId } = useParams();
  const { isDark } = useOne();
  const [range, setRange] = useState<{ start: Date, end: Date }>({ start: new Date(), end: new Date() });

  const [metaData, setMetaData] = useState<LocationData>();

  useEffect(() => {
    (async () => {
      let response = await fetch(`https://api.vatten.carera.se/locations/${locationId}`);

      let data = await response.json();

      if (!data.status) {
        data.status = [];
      }
      // sort status by severity descending
      data.status.sort((a: Status, b: Status) => {
        if (a.severity > b.severity) return -1;
        if (a.severity < b.severity) return 1;
        return 0;
      });


      setMetaData(data);
    })();
  }, [locationId]);

  // let highestSeverity = data.status.reduce((acc: number, curr: Status) => {
  //   return curr.severity > acc ? curr.severity : acc;
  // }
  //   , 0);

  // computed status using hooks
  const highest_status = useMemo(() => {
    if (!metaData) return 0;
    const highestSeverity = metaData.status.reduce((acc: number, curr: Status) => {
      return curr.severity > acc ? curr.severity : acc;
    }
      , 0);
    return highestSeverity;
  }
    , [metaData]);

  const severityToStatus = (severity: number) => {
    if (severity === 0) return 'OK';
    if (severity === 1) return 'Warning';
    if (severity === 2) return 'Critical';
    return 'Unknown';
  }

  const severityToColor = (severity: number) => {
    if (severity === 0) return 'green';
    if (severity === 1) return 'orange';
    if (severity === 2) return 'red';
    return 'grey';
  }

  const statusIcon = (severity: number) => {
    if (severity === 0) return 'tick-circle';
    if (severity === 1) return 'warning-sign';
    if (severity === 2) return 'error';
    return 'help';
  }

  const statusTypeToName = (type: string) => {
    if (type === 'bme_null') return 'No data from environmental sensors';
    if (type === 'older_than_3hours') return 'Last contact more than 3 hours ago';
    if (type === 'older_than_24hours') return 'Last contact more than 24 hours ago';
    if (type === 'diver_null') return 'No data from diver';
    return 'Unknown';
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <Card style={{ flex: '1 1 100%' }}>
        <div className="stats-container" style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
          {
            metaData && (
              <>
                <h3 style={{ flex: '1 1 100%', marginBottom: '0px' }}>Location - {metaData.name}</h3>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
                  <Card>
                    <table className="bp5-html-table bp5-html-table-striped">
                      <tbody>
                        <tr>
                          <td>Name</td>
                          <td>{metaData.name}</td>
                        </tr>
                        <tr>
                          <td>Project</td>
                          <td><Link to={`/projects/${metaData.project_id}`}>{metaData.project_name}</Link></td>
                        </tr>
                        <tr>
                          <td>Sensor Id</td>
                          <td>{metaData.sensor_id}</td>
                        </tr>
                        <tr>
                          <td>Status</td>
                          <td>
                            {metaData.ts ?
                              <Popover
                                popoverClassName={Classes.POPOVER_CONTENT_SIZING}
                                content={(metaData.status.map((s, i) => <div key={i} className={severityToColor(s.severity)}>{statusTypeToName(s.type)}</div>) as any)}
                                enforceFocus={true}
                                interactionKind="hover"
                                disabled={metaData.status.length === 0}
                              >
                                <div className={severityToColor(highest_status)}>
                                  <Icon icon={statusIcon(highest_status)} iconSize={20} style={{ marginRight: '10px' }} />
                                  <span>{severityToStatus(highest_status)}</span>
                                </div>
                              </Popover> : 'N/A'
                            }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Card>
                  <Card>
                    <table className="bp5-html-table bp5-html-table-striped">
                      <tbody>
                        <tr>
                          <td>Latest reading</td>
                          <td>{metaData.ts ? formatDistanceToNow(new Date(metaData.ts), { addSuffix: true }) : 'N/A'}</td>
                        </tr>
                        <tr>
                          <td>Signal</td>
                          <td>
                            {metaData.signal ?
                              <Tooltip content={metaData.signal + ' dBm'} intent={Intent.PRIMARY}>
                                <div>
                                  {getSignalQuality(metaData.signal)}
                                  <Icon icon="dot" color={metaData.signal > -15 ? 'green' : 'orange'} />
                                </div>
                              </Tooltip> : 'N/A'}
                          </td>
                        </tr>
                        <tr>
                          <td>Level</td>
                          <td>{metaData.level ? `${metaData.level?.toFixed(3)}m` : 'N/A'}</td>
                        </tr>
                        <tr>
                          <td>Temperature</td>
                          <td>{metaData.temp ? `${metaData.temp?.toFixed(2)}°C` : 'N/A'}</td>
                        </tr>
                      </tbody>
                    </table>
                  </Card>
                </div>

                {/* <StatCard title="Status" value={severityToStatus(highest_status)} iconName={statusIcon(highest_status)} color={severityToColor(highest_status)} tooltip={metaData.status.length != 0 && metaData.status.map((s, i) => <div key={i} className={severityToColor(s.severity)}>{statusTypeToName(s.type)}</div>)} />
                <StatCard title="Latest reading" value={formatDistanceToNow(new Date(metaData.ts), { addSuffix: true })} iconName="time" color="green" />
                <StatCard title="Level" value={metaData.level ? `${metaData.level?.toFixed(3)}m` : 'N/A'} iconName="timeline-bar-chart" color="blue" />
                <StatCard title="Temperature" value={metaData.temp ? `${metaData.temp?.toFixed(2)}°C` : 'N/A'} iconName="temperature" color="orange" /> */}
              </>
            )
          }
        </div>
      </Card >
      {
        <Card style={{ flex: '1 1 100%' }}>
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
            <h3>Historical</h3>
            {range && range.end.getTime() - range.start.getTime() > 3 * 60 * 60 * 1000 &&
              <div style={{ marginLeft: 'auto' }}>
                {/* <button onClick={() => export_data(locationId as any, range)} className="bp3-button bp3-intent-primary bp3-icon-download">Export</button> */}
                {/* <button onClick={() => export_data_hourly(locationId as any, range)} className="bp3-button bp3-intent-primary bp3-icon-download">Export hourly</button>
                <button onClick={() => export_data_daily(locationId as any, range)} className="bp3-button bp3-intent-primary bp3-icon-download">Export daily</button> */}
                <ButtonGroup>
                  <Button icon="download" intent={Intent.NONE} onClick={() => export_data_hourly(locationId as any, range)}>Export hourly</Button>
                  <Button icon="download" intent={Intent.NONE} onClick={() => export_data_daily(locationId as any, range)}>Export daily</Button>
                </ButtonGroup>
              </div>
            }
          </div>

          <Card interactive={true} elevation={Elevation.ONE} style={{ padding: 0 }}>
            {
              locationId && <BarChart isDark={isDark} locationId={locationId} onChangeRange={setRange} />
            }
          </Card>
        </Card>}
    </div >
  );
}


export default Location;

const StatCard = ({ title, value, iconName, color, tooltip }: { title: string, value: string, iconName: any, color: string, tooltip?: any }) => (
  <Card style={{ flex: '1 1 300px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
    <H5>{title}</H5>

    <Popover
      popoverClassName={Classes.POPOVER_CONTENT_SIZING}
      content={tooltip}
      enforceFocus={true}
      // isOpen={false}
      disabled={tooltip ? false : true}
      interactionKind="hover"
    >
      <div style={{ display: 'flex', alignItems: 'center' }} className={color}>
        <Icon icon={iconName} iconSize={20} style={{ marginRight: '10px' }} />
        <span>{value}</span>
      </div>
    </Popover >
  </Card >
);

type LocationData = {
  id: string;
  project_id: string;
  project_name: string;
  sensor_id: string;
  name: string;
  lon: number;
  lat: number;
  ts: number;
  level: number;
  temp: number;
  signal: number;
  status: Status[];
};

type Status = {
  type: string;
  severity: number;
};
function getSignalQuality(signal: number) {
  if (signal > -10) {
    return 'Excellent';
  } else if (signal > -15) {
    return 'Good';
  } else if (signal > -20) {
    return 'Fair';
  } else {
    return 'Poor';
  }
}


async function export_data_hourly(locationId: string, range: { start: Date, end: Date }) {

  let startDate = range.start;
  let endDate = range.end;

  let response = await fetch(`https://api.vatten.carera.se/export_hourly/${locationId}/${startDate.getTime()}/${endDate.getTime()}`);

  if (!response.ok) {
    console.error('Error fetching data');
    return;
  }

  let data = await response.json();

  let series = make_series(data);

  let csv = 'ts,level,temp\n';
  series.forEach((d: any) => {
    csv += `${d.ts.toISOString()},${d.level ? d.level.toFixed(4) : ''},${d.temp ? d.temp.toFixed(2) : ''}\n`;
  });

  let blob = new Blob([csv], { type: 'text/csv' });
  let url = window.URL.createObjectURL(blob);
  window.open(url);
}
async function export_data_daily(locationId: string, range: { start: Date, end: Date }) {

  let startDate = range.start;
  let endDate = range.end;

  let response = await fetch(`https://api.vatten.carera.se/export_daily/${locationId}/${startDate.getTime()}/${endDate.getTime()}`);

  if (!response.ok) {
    console.error('Error fetching data');
    return;
  }

  let data = await response.json();

  let series = make_series(data);

  let csv = 'ts,level,temp\n';
  series.forEach((d: any) => {
    csv += `${d.ts.toISOString()},${d.level ? d.level.toFixed(4) : ''},${d.temp ? d.temp.toFixed(2) : ''}\n`;
  });

  let blob = new Blob([csv], { type: 'text/csv' });
  let url = window.URL.createObjectURL(blob);
  window.open(url);
}
function make_series(data: any): any {
  let tsMap = new Map<string, { ts: Date, level?: number, temp?: number }>();

  for (let i = 0; i < data.aggregations.by_id.buckets.length; i++) {
    for (let j = 0; j < data.aggregations.by_id.buckets[i].levels_and_id.buckets.length; j++) {

      let point = {
        ts: new Date(data.aggregations.by_id.buckets[i].levels_and_id.buckets[j].key_as_string),
        level: data.aggregations.by_id.buckets[i].levels_and_id.buckets[j].average_level.value
      };

      if (point.level === null) continue;
      if (tsMap.has(point.ts.toISOString())) {
        tsMap.get(point.ts.toISOString())!.level = point.level;
      } else {
        tsMap.set(point.ts.toISOString(), point);
      }
    }
    for (let j = 0; j < data.aggregations.by_id.buckets[i].temps_and_id?.buckets.length; j++) {
      let temp = {
        ts: new Date(data.aggregations.by_id.buckets[i]?.temps_and_id.buckets[j]?.key_as_string),
        temp: data.aggregations.by_id.buckets[i].temps_and_id.buckets[j].average_temp.value
      };

      if (temp.temp === null) continue;
      if (tsMap.has(temp.ts.toISOString())) {
        tsMap.get(temp.ts.toISOString())!.temp = temp.temp;
      } else {
        tsMap.set(temp.ts.toISOString(), temp);
      }
    }
  }

  let d: any[] = [];
  tsMap.forEach((v, k) => {
    d.push({ ts: v.ts, level: v.level, temp: v.temp });
  });

  d.sort((a, b) => a.ts.getTime() - b.ts.getTime());

  return d;
}