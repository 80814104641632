import { useCallback, useState } from 'react';
import './App.scss';
import { Alignment, Button, Classes, Drawer, Navbar, Position } from "@blueprintjs/core";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Location from './routes/Location';
import { useOne } from './services/one';
import classNames from 'classnames';
import Organization from './routes/Organization';
import Project from './routes/Project';

function App() {
  const { isDark, setIsDark } = useOne();

  const themeIcon = isDark ? "flash" : "moon";

  const [isOpen, setIsOpen] = useState(false);
  const toggleOverlay = useCallback(() => setIsOpen(open => !open), [setIsOpen]);

  return (
    <div className={classNames("app", {
      [Classes.DARK]: isDark,
    })}>
      <div className="root">
        <Navbar>
          <Navbar.Group align={Alignment.LEFT}>
            <Button className="bp5-minimal" icon="menu" onClick={toggleOverlay} />
            <Navbar.Divider />
            <Navbar.Heading>redroc Navigator</Navbar.Heading>
          </Navbar.Group>
          <Navbar.Group align={Alignment.RIGHT}>
            <Button className="bp5-minimal" icon={themeIcon} onClick={() => setIsDark(dark => !dark)} />
          </Navbar.Group>
        </Navbar>
        <div className="container">
          <Drawer className={classNames("app", {
            [Classes.DARK]: isDark,
          })} title="Menu" isOpen={isOpen} onClose={toggleOverlay} position={Position.LEFT}>
            <div className={Classes.DRAWER_BODY}>

            </div>
          </Drawer>
          <div className='content'>
            <RouterProvider router={router} />
          </div>
        </div>
      </div>
    </div >
  );
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Location />,
  },
  {
    path: "/locations/:locationId",
    element: <Location />,
  },
  {
    path: "/organizations/:organizationId",
    element: <Organization />,
  },
  {
    path: "/projects/:projectId",
    element: <Project />,
  }
]);

export default App;
