import { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Card } from "@blueprintjs/core";
import { Cell, Column, Table2 } from "@blueprintjs/table";
import { formatDistanceToNow } from 'date-fns';



const Project = () => {
  // let { organizationId } = useParams();
  let { projectId } = useParams();
  // let [organization, setOrganization] = useState();

  // organization should have type any
  // let [organization, setOrganization] = useState<any>();
  let [project, setProject] = useState<any>();


  // // Adaptation for Breadcrumbs
  // const breadcrumbs: any = [
  //   { text: "Organization", href: "/" },
  //   // Add more breadcrumbs as needed
  // ];

  useEffect(() => {
    (async () => {
      // let response = await fetch(`https://api.vatten.carera.se/organizations/${organizationId}`);
      let response = await fetch(`https://api.vatten.carera.se/projects/${projectId}`);

      let data = await response.json();

      // loop over all locations and get highest severity
      data.locations.forEach((location: any) => {

        if (!location.status) {
          location.status = [];
        }

        // sort status by severity descending
        location.status.sort((a: any, b: any) => {
          if (a.severity > b.severity) return -1;
          if (a.severity < b.severity) return 1;
          return 0;
        });

        let highestSeverity = 0;
        if (location.status.length > 0) {
          highestSeverity = location.status[0].severity;
        }
        if (!location.ts) {
          highestSeverity = -1;
        }

        location.highestSeverity = highestSeverity;
      });

      // setOrganization(data);
      setProject(data);
    })();
  }, [projectId]);

  const severityToStatus = (severity: number) => {
    if (severity === 0) return 'OK';
    if (severity === 1) return 'Warning';
    if (severity === 2) return 'Critical';
    return 'Unknown';
  }

  const severityToColor = (severity: number) => {
    if (severity === 0) return 'green';
    if (severity === 1) return 'orange';
    if (severity === 2) return 'red';
    return 'grey';
  }

  const statusIcon = (severity: number) => {
    if (severity === 0) return 'tick-circle';
    if (severity === 1) return 'warning-sign';
    if (severity === 2) return 'error';
    return 'help';
  }

  const statusTypeToName = (type: string) => {
    if (type === 'bme_null') return 'No data from environmental sensors';
    if (type === 'older_than_3hours') return 'Last contact more than 3 hours ago';
    if (type === 'older_than_24hours') return 'Last contact more than 24 hours ago';
    if (type === 'diver_null') return 'No data from diver';
    return 'Unknown';
  }

  const sortLocations = (locations: any) => {
    return locations.sort((a: any, b: any) => {
      // sort on highest severity then on name ascending
      if (a.highestSeverity > b.highestSeverity) return -1;
      if (a.highestSeverity < b.highestSeverity) return 1;
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
  }

  const locationsSorted = useMemo(() => {
    if (project && project.locations) {
      return sortLocations(project.locations);
    } else {
      return [];
    }
  }, [project]);

  return (
    <div>
      {/* <Breadcrumbs items={breadcrumbs} /> */}
      <div className="site-layout-content">
        {project && (
          <Card>
            <h3>Project - {project.name}</h3>
            <Table2 numRows={locationsSorted.length} columnWidths={[null, 75, 100, null]}>
              <Column
                name="Location"
                cellRenderer={(rowIndex: number) => (
                  <Cell>
                    <Link
                      to={`/locations/${locationsSorted[rowIndex].id}`}
                      style={{
                        display: 'block',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {locationsSorted[rowIndex].name}
                    </Link>
                  </Cell>
                )}
              />
              <Column
                name="Status"
                cellRenderer={(rowIndex: number) => (
                  <Cell>
                    {locationsSorted[rowIndex].ts && <span className={severityToColor(locationsSorted[rowIndex].highestSeverity)}
                    >
                      {severityToStatus(locationsSorted[rowIndex].highestSeverity)}
                    </span>}
                  </Cell>
                )}
              />
              <Column
                name="Level"
                cellRenderer={(rowIndex: number) => (
                  <Cell>
                    <span>
                      {locationsSorted[rowIndex].level && locationsSorted[rowIndex].level.toFixed(3) + "m"}
                    </span>
                  </Cell>
                )}
              />
              <Column
                name="Last Reading"
                cellRenderer={(rowIndex: number) => (
                  <Cell>
                    <span>
                      {locationsSorted[rowIndex].ts && formatDistanceToNow(new Date(locationsSorted[rowIndex].ts), { addSuffix: true })}
                    </span>
                  </Cell>
                )} />
            </Table2>
          </Card>
        )}
      </div>
    </div>
  );
};

export default Project;

// {
//   "name": "City Link",
//   "locations": [
//       {
//           "id": "5DxtJC07",
//           "ts": 1709111954625,
//           "name": "13CW203RB",
//           "level": 2.274800109863281,
//           "lon": 18.054827516216942,
//           "lat": 59.37059022218584,
//           "sensor_id": "q19HlEeT"
//       },
//       {
//           "id": "_-mt15_I",
//           "ts": 1709113025448,
//           "name": "13CW113RB",
//           "level": 6.919400081634522,
//           "lon": 18.044766,
//           "lat": 59.386087,
//           "sensor_id": "EjVbxLUj"
//       }
//   ]
// }