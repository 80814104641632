import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Card, HotkeysProvider } from "@blueprintjs/core";
import { Cell, Column, Table2, TruncatedFormat } from "@blueprintjs/table";

const Organization = () => {
  let { organizationId } = useParams();
  // let [organization, setOrganization] = useState();

  // organization should have type any
  let [organization, setOrganization] = useState<any>();

  // // Adaptation for Breadcrumbs
  // const breadcrumbs: any = [
  //   { text: "Organization", href: "/" },
  //   // Add more breadcrumbs as needed
  // ];

  useEffect(() => {
    (async () => {
      let response = await fetch(`https://api.vatten.carera.se/organizations/${organizationId}`);

      let data = await response.json();

      setOrganization(data);
    })();
  }, [organizationId]);


  return (
    <div>
      {/* <Breadcrumbs items={breadcrumbs} /> */}
      <div className="site-layout-content">
        {organization && (
          <Card>
            <h3>Organization - {organization.name}</h3>


            <HotkeysProvider>
              <Table2 numRows={organization.projects.length} columnWidths={[300]}>
                <Column
                  name="Project"
                  cellRenderer={(rowIndex: number) => (
                    <Cell>
                      <Link
                        to={`/projects/${organization.projects[rowIndex].id}`}
                      >
                        <TruncatedFormat>{organization.projects[rowIndex].name}</TruncatedFormat>
                      </Link>
                    </Cell>
                  )}
                />
              </Table2>
            </HotkeysProvider>
          </Card>
        )}
      </div>
    </div>
  );
};

export default Organization;
