import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BlueprintProvider, OverlaysProvider } from '@blueprintjs/core';
import App from './App';
import { OneProvider } from './services/one';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    {/* <Auth0Provider
      domain="redroc.eu.auth0.com"
      clientId="GE1A8LjVguYeIIVgbtIB3HosRHxyP0Pp"
      authorizationParams={{
        redirect_uri: window.location.origin
      }}
    > */}
    <BlueprintProvider>
      <OverlaysProvider>
        <OneProvider>
          <App />
        </OneProvider>
      </OverlaysProvider>
    </BlueprintProvider>
    {/* </Auth0Provider> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
