import { PlotRelayoutEvent } from 'plotly.js';
import React, { useEffect, useRef, useState } from 'react';
import Plot from 'react-plotly.js';

const BarChart = ({ isDark, locationId, onChangeRange }: { isDark: boolean, locationId: string, onChangeRange?: (range: { start: Date, end: Date }) => void }) => {
  const [data, setData] = useState<Plotly.Data[]>([]);
  const [layout, setLayout]: any = useState({
    autosize: true,
    transition: {
      duration: 500,
      easing: 'cubic-in-out'
    },
    xaxis: { type: 'date' },
    yaxis: {
      title: 'Level (m)',
      overlaying: 'y2',
      tickformat: '.3f',
      fixedrange: true,
    },
    yaxis2: {
      title: 'Temperature (°C)',
      side: 'right',
      tickformat: '.2f',
      fixedrange: true,
    },
    // yaxis3: {
    //   overlaying: 'y2',
    //   tickformat: '.2f',
    //   fixedrange: true,
    //   showgrid: false, // No grid lines
    //   zeroline: false, // No zero line
    //   showline: false, // No axis line
    //   showticklabels: false, // No tick labels
    //   ticks: '', // No ticks
    // },
    hovermode: 'x unified',
    margin: {
      t: 0,
      r: 80,
      b: 50,
      l: 80,
      pad: 4
    },
    legend: {
      orientation: 'h',
      y: 1.2,
      x: 0.5,
      xanchor: 'center',
      yanchor: 'top',
    },
  });

  useEffect(() => {
    const handleResize = (entries: any) => {
      const { width } = entries[0].contentRect;
      setLayout((prevLayout: any) => ({
        ...prevLayout,
        yaxis: { ...prevLayout.yaxis, visible: width >= 400 },
        yaxis2: { ...prevLayout.yaxis2, visible: width >= 400 },
        yaxis3: { ...prevLayout.yaxis3, visible: width >= 400 },
        margin: { ...prevLayout.margin, l: width < 400 ? 0 : 80, r: width < 400 ? 0 : 80 },
      }));
    };

    const observer = new ResizeObserver(handleResize);
    if (plotContainerRef.current) {
      observer.observe(plotContainerRef.current);
    }

    return () => observer.disconnect();
  }, []);

  let skipNext = false;
  const handleRelayout = async (event: any) => {
    if (skipNext) {
      skipNext = false;
      return;
    }
    skipNext = true;

    const designer = (d: any) => {
      let tempMax = Math.max(...d.tempSeries);
      let tempMin = Math.min(...d.tempSeries.filter((p: number) => p !== null));

      let range = tempMax - tempMin;

      if (range < 1) {
        let midPoint = (tempMax + tempMin) / 2;
        tempMin = midPoint - 0.5 - 0.2;
        tempMax = midPoint + 0.5 - 0.2;
      }

      let tsMax = Math.max(...d.levels_x.map((p: Date) => p.getTime()));
      let tsMin = Math.min(...d.levels_x.map((p: Date) => p.getTime()));

      let rangeTs = tsMax - tsMin;

      tsMin = tsMin - rangeTs * 0.01;
      tsMax = tsMax + rangeTs * 0.01;

      let levelMax = Math.max(...d.mainSeries.filter((p: number) => p !== null && !isNaN(p)));
      let levelMin = Math.min(...d.mainSeries.filter((p: number) => p !== null && !isNaN(p)));

      let rangeLevel = levelMax - levelMin;

      rangeLevel = rangeLevel < 0.1 ? 0.1 : rangeLevel;

      levelMin = levelMin - rangeLevel * 0.1;
      levelMax = levelMax + rangeLevel * 0.1;

      setLayout((prevLayout: any) => ({
        ...prevLayout,
        yaxis: { ...prevLayout.yaxis, range: [levelMin, levelMax] },
        xaxis: { ...prevLayout.xaxis, range: [tsMin, tsMax] },
        yaxis2: { ...prevLayout.yaxis2, range: [tempMin, tempMax] },
        yaxis3: { ...prevLayout.yaxis3, range: [-20, 0] },
      }));
    }


    if (event['xaxis.range[0]'] === undefined || event['xaxis.range[1]'] === undefined) {
      const agg_data = await fetchDataInitial(locationId);

      if (agg_data === undefined) return;

      let d = make_series(agg_data);

      designer(d);

      if (onChangeRange) {
        onChangeRange({ start: d.levels_x[0], end: d.levels_x[d.levels_x.length - 1] });
      }

      setData(createSeries(d));
    } else {
      const agg_data = await fetchDataBasedOnZoom(event, locationId);

      if (agg_data === undefined) return;


      let d = make_series(agg_data);

      designer(d);

      if (onChangeRange) {
        onChangeRange({ start: new Date(event['xaxis.range[0]'] as any), end: new Date(event['xaxis.range[1]'] as any) });
      }

      setData(createSeries(d));
    }
  };


  const plotContainerRef = useRef(null);
  return (
    <div ref={plotContainerRef} className={isDark ? "dark_plotly_container" : "light_plotly_container"}>
      <Plot
        data={data}
        layout={layout}
        config={{ displayModeBar: false, responsive: true }}
        useResizeHandler={true}
        style={{ width: "100%", height: "300px" }}
        onRelayout={handleRelayout}
      />
    </div>
  );
};

export default BarChart;


async function fetchDataInitial(id: string) {
  try {
    let response = await fetch(`https://api.vatten.carera.se/vatten5_init/${id}`);

    let data = await response.json();

    return data;
  } catch (e) {
    console.error('Error fetching data');
    return;
  }


}

async function fetchDataBasedOnZoom(event: Readonly<PlotRelayoutEvent>, id: string) {
  let startDate = new Date(event['xaxis.range[0]'] as any);
  let endDate = new Date(event['xaxis.range[1]'] as any);

  let range = endDate.getTime() - startDate.getTime();
  startDate = new Date(startDate.getTime() - range * 0.05);
  endDate = new Date(endDate.getTime() + range * 0.05);

  let response = await fetch(`https://api.vatten.carera.se/vatten5/${id}/${startDate.getTime()}/${endDate.getTime()}`);

  if (!response.ok) {
    console.error('Error fetching data');
    return;
  }

  let data = await response.json();

  return data;
}

function make_series(data: any): any {
  let levels: { ts: Date, min: number, max: number, avg: number }[] = [];
  let temps: { ts: Date, temp: number }[] = [];
  let singal: { ts: Date, signal: number }[] = [];
  for (let i = 0; i < data.aggregations.by_id.buckets.length; i++) {
    for (let j = 0; j < data.aggregations.by_id.buckets[i].levels_and_id.buckets.length; j++) {

      let point = {
        ts: new Date(data.aggregations.by_id.buckets[i].levels_and_id.buckets[j].key_as_string),
        min: data.aggregations.by_id.buckets[i].levels_and_id.buckets[j].min_level.value,
        max: data.aggregations.by_id.buckets[i].levels_and_id.buckets[j].max_level.value,
        avg: data.aggregations.by_id.buckets[i].levels_and_id.buckets[j].average_level.value
      };

      levels.push(point);
    }
    for (let j = 0; j < data.aggregations.by_id.buckets[i].temps_and_id?.buckets.length; j++) {
      let temp = {
        ts: new Date(data.aggregations.by_id.buckets[i]?.temps_and_id.buckets[j]?.key_as_string),
        temp: data.aggregations.by_id.buckets[i].temps_and_id.buckets[j].average_temp.value
      };
      temps.push(temp);
    }

    for (let j = 0; j < data.aggregations.by_id.buckets[i].temps_and_id?.buckets.length; j++) {
      let sig = {
        ts: new Date(data.aggregations.by_id.buckets[i]?.temps_and_id.buckets[j]?.key_as_string),
        signal: data.aggregations.by_id.buckets[i].temps_and_id.buckets[j].average_signal.value
      };
      singal.push(sig);
    }
  }

  levels.sort((a, b) => a.ts.getTime() - b.ts.getTime());
  temps.sort((a, b) => a.ts.getTime() - b.ts.getTime());
  singal.sort((a, b) => a.ts.getTime() - b.ts.getTime());

  let granularity = data.granularity; // interval in ms

  const levelsResult = fillTimeSeries(levels, granularity);

  let tempsResult = temps;
  if (granularity > 3600000)
    tempsResult = fillTimeSeries(temps, granularity);

  let singalResult = singal;
  if (granularity > 3600000)
    singal = fillTimeSeries(singal, granularity);

  let levels_x = levelsResult.map((p: any) => p.ts);
  let minSeries = levelsResult.map((p: any) => p.min);
  let mainSeries = levelsResult.map((p: any) => p.avg);
  let maxSeries = levelsResult.map((p: any) => p.max);


  let temps_x = tempsResult.map((p: any) => p.ts);
  let tempSeries = tempsResult.map((p: any) => p.temp);

  let signals_x = singalResult.map((p: any) => p.ts);
  let signalSeries = singalResult.map((p: any) => p.signal);

  let d = {
    levels_x,
    minSeries,
    mainSeries,
    maxSeries,
    temps_x,
    tempSeries,
    granularity,
    signals_x,
    signalSeries
  };

  return d;
}

function createSeries(d: any): Plotly.Data[] {
  return [
    {
      x: d.levels_x,
      y: d.minSeries,
      type: 'scatter',
      mode: 'lines',
      line: {
        color: '#add8e6',
        shape: 'spline',
        smoothing: 0.5,
      },
      showlegend: false,
      hoverinfo: 'none',
    },
    {
      x: d.levels_x,
      y: d.maxSeries,
      type: 'scatter',
      mode: 'lines',
      line: {
        color: '#add8e6',
        shape: 'spline',
        smoothing: 0.5,
      },
      fillcolor: '#b3d1ff',
      showlegend: false,
      hoverinfo: 'none',
    },
    {
      name: 'Average Level',
      x: d.levels_x,
      y: d.mainSeries,
      type: 'scatter',
      mode: 'lines',
      line: {
        color: '#003366',
        shape: 'spline',
        smoothing: 0.5,
      },
      marker: { color: '#003366' },
      hovertemplate: 'Level: %{y:.3f} m<extra></extra>',
    },
    {
      name: 'Temperature',
      x: d.temps_x,
      y: d.tempSeries,
      type: 'scatter',
      mode: d.granularity <= 900000 ? 'lines+markers' : 'lines',
      line: {
        color: '#ffb27f',
        shape: 'spline',
        smoothing: 0.5,
      },
      marker: { color: '#ffb27f' },
      connectgaps: d.granularity <= 900000 ? true : false,
      yaxis: 'y2',
      hovertemplate: 'Temperature: %{y:.2f} °C<extra></extra>',
    },
    // {
    //   name: 'Signal',
    //   x: d.signals_x,
    //   y: d.signalSeries,
    //   type: 'scatter',
    //   mode: d.granularity <= 900000 ? 'lines+markers' : 'lines',
    //   line: {
    //     color: '#ff0000',
    //     shape: 'spline',
    //     smoothing: 0.5,
    //   },
    //   marker: { color: '#ff0000' },
    //   connectgaps: d.granularity <= 3600000 ? true : false,
    //   yaxis: 'y3',
    //   hovertemplate: 'Signal: %{y:.2f} dB<extra></extra>',
    // },
  ];
}

function fillTimeSeries(data: any[], granularity: number): any[] {
  if (data.length === 0) return [];

  const startTime: number = data[0].ts.getTime();
  const endTime: number = data[data.length - 1].ts.getTime();
  const map = new Map(data.map((p) => [p.ts.getTime(), p]));

  const arr: any[] = [];

  for (let currentTime = startTime; currentTime <= endTime; currentTime += granularity) {
    const currentData = map.get(currentTime);
    if (currentData) {
      arr.push({ ...currentData, ts: new Date(currentData.ts) });
    } else {
      arr.push({ ts: new Date(currentTime), temp: null });
    }
  }

  return arr;
}
