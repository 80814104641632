// OneContext that has some state and functions to update that state. hooks

import React from 'react';
import { createContext, useContext } from 'react';

interface OneContextProps {
    isDark: boolean;
    setIsDark: React.Dispatch<React.SetStateAction<boolean>>;
}

const OneContext: React.Context<OneContextProps> = createContext(undefined) as any;

export const useOne = () => useContext(OneContext);

export const OneProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isDark, setIsDark] = React.useState(
        () => window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
    );

    return (
        <OneContext.Provider value={{ isDark, setIsDark }}>
            {children}
        </OneContext.Provider>
    );
};

export default OneContext;